

















import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  data: () => ({
    rules: [(value: any) => !!value || "Required"],
    username: "",
    password: "",
    logged: false,
  }),
  methods: {
    login: function () {
      if (this.username != "" && this.password != "") {
        console.log("posting");
        axios
          .post(Vue.prototype.serverAddr + "/api/login", {
            username: this.username.trim(),
            password: this.password.trim(),
          })
          .then((response) => {
            console.log("get response")
            if (response.data == "success") {
              console.log("success")
              Vue.prototype.logged = true;
              this.$router.push('/backstage/article')
              
            }
          });
      } else {
        console.log(this.username, this.password);
      }
    },
  },
});
